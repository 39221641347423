.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html {
  height: 100%;
  min-height: 100% !important;
  margin: 0;
}

body {
  font-family: Montserrat Alternates !important;
  height: 100%;
  min-height: 100% !important;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  color: "#000" !important;
  background-color: #F6F8FC;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

.content {
  margin: 20px 250px 20px 250px;
  position: relative;
}

.nav-link {
  margin-right: 8px;
  margin-left: 8px;
  text-decoration: none;
  color: inherit;
}

.rules {
  border-collapse: collapse;
  width: 100%;
}

.rules td,
#rules th {
  border: 0px;
  border-bottom: 1px solid #EEE;
  padding: 8px;
}

.rules tr:hover {
  background-color: #3069701A;
}

.rules th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3069701A;
}

.data-grid tr:nth-child(odd) {
  background: whitesmoke;
}

.data-grid tr:nth-child(even) {
  background: white;
}


.cell {
  background: white;
  border: 0px !important;
}

h3 {
  color: #26565B;
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
}

h4 {
  color: #26565B;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
}

.menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 0px;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.arrow-link {
  display: flex;
  color: #398089;
  background-color: transparent;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  letter-spacing: 1px;
  font-size: 16px;
  font-family: Roboto;
}

.arrow-link > .arrow {
  width: 8px;
  height: 8px;
  border-right: 2px solid #398089;
  border-bottom: 2px solid #398089;
  position: relative;
  transform: rotate(-45deg);
  margin-left: 3px;
  transition: all .3s ease;
}

.arrow-link > .arrow::before {
  display: block;
  background-color: #398089;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-link:hover > .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: #398089;
}

.arrow-link:hover > .arrow::before {
  opacity: 1;
  width: 8px;
}

.arrow-link:hover {
  color: #398089;
}
