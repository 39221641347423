html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat Alternates;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loading {
  display: flex;
  min-height: 500px;
  align-items: center;
  justify-content: center;
}

::placeholder {
  color: rgb(85, 85, 85) !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: rgb(85, 85, 85) !important;
}

::-ms-input-placeholder {
  color: rgb(85, 85, 85) !important;
}

input[type] {
  background-color: #FFF;
}

select {
  height: 38px;
  padding: 10px;
  padding-left: 20px;
  color: #000;
  cursor: auto;
  background-color: #FFF;
}

label {
  font-family: Montserrat Alternates !important;
  width: 100%;
}

span {
  color: #26565B;
}

table.data-grid {
  margin: auto;
  width: 100%;
}

table.data-grid td {
  width: 120px;
}

table.data-grid div {
  padding: 5px;
}

.data-grid-container .data-grid .cell.read-only {
  vertical-align: top;
}

.disabled-row {
  color: #aaa !important;
}

details {
  border-bottom: 1px solid #aaa;
  padding: 20px;
}

summary {
  color: #26565B;
  font-family: Roboto;
  font-size: 20px;
  margin: -.5em -.5em 0;
  padding: .5em;
}

h4 {
  color: #26565B;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
}

details[open] summary {
  margin-bottom: .5em;
}


.top-tooltip {
  z-index: 200 !important;
  position: absolute !important;
  top: 50px !important;
  left: 0px !important;
  width: 105px !important;
}

.calendar-active {
  background-color: white !important;
}

.grid-error {
  background-color: red;
}

.date-pattern-error {
  background-color: rgba(235, 8, 0, 0.17);
  border-radius: 5px;
}

.date-pattern-warning {
  background-color: rgba(173, 104, 0, 0.17);
  border-radius: 5px;
}

.reactgrid-wrapper {
  max-width: 3080px;
  width: calc(90vw - 25px);
  max-height: calc(60vh - 65px);
  overflow: auto;
  background-color: #F6F8FC;
  margin-top: 30px;
  margin-bottom: 30px;
}

.react-datepicker__day--highlighted {
  background-color: #306970;
}

.react-datepicker__day--highlighted:hover {
  background-color: #398089;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #306970;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color: #306970;
}

.react-datepicker__day--in-selecting-range:not(
  .react-datepicker__day--in-range
) {
  background-color: #8ea1a4;
}

.calendar-task .month-container {
  padding-bottom: 45px !important;
}

.calendar-task table {
  border-spacing: 1px;
}

.calendar-task .day {
  font-weight: bold;
}

.calendar-task .calendar {
  background-color: white;
  color: #26565B;
}

.calendar-task td:last-child {
  color: #C91212;
}

.calendar-task .month th:first-child {
  background-color: white;
}

.calendar-task .week-number {
  font-weight: normal !important;
  background-color: #CDDFC9;
  color: #26565B;
  border-radius: 6px;
}

.calendar-task .day-customized {
  background-color: #c2d2d4;
}

.calendar-task .day-selected {
  border: 1px solid green;
}

.calendar-task .day-red {
  color: #C91212;
}

.calendar-task .day-grey {
  color: #D0D0D0;
}

.calendar-task .day-background-yellow {
  background-color: #e0d775;
}

.calendar-task .day-background-green {
  background-color: #67B59D;
}

.input-highlight {
  background-color: #e4f8e0 !important;
  border: 1px solid #4398a1;
  box-shadow: 0 0 2px #4398a1;
}
.input-partial-highlight {
  background: linear-gradient(45deg, white 65%, #e4f8e0 50%) !important;
  border: 1px solid #4398a1;
  box-shadow: 0 0 2px #4398a1;
}

.input-highlight-read-only {
  outline: 3px solid #4398a1;
  box-shadow: 0 0 2px #4398a1;
}

.input-read-only {
  box-shadow: 0 0 2px;
  background: lightgrey !important;
}

.react-datepicker {
  height: 300px;
}